import React, {Suspense} from "react";
import { Canvas } from "@react-three/fiber";
import './styles.css'
import { OrbitControls, PerspectiveCamera, CubeCamera, Environment } from "@react-three/drei";
import { Ground } from "./Ground";
import { Car } from './Car';
import {Rings} from './Rings';
import { Boxes } from "./Boxes";
import { Bloom, ChromaticAberration, DepthOfField, EffectComposer } from "@react-three/postprocessing";
import {BlendFunction} from 'postprocessing'
import { FloatingGrid } from "./FloatingGrid";

function CarShow() {
  return(
    <>
    <OrbitControls maxPolarAngle={1.45} target={[0,0.35,0]} />

    <PerspectiveCamera position={[3,2,5]} makeDefault fov={50} />

    <color attach='background' args={[0,0,0]}/>

<CubeCamera resolution={256} frames={Infinity}>
  {(texture) => (
    <>
    <Environment map={texture} />
    <Car/>
    {/* <Boxes/> */}
    </>
  )

  }
</CubeCamera>


    <Rings/>

    <spotLight
    shadow-bias={0.0001}
    castShadow
    position={[5,5,0]}
    penumbra={0.5}
    angle={0.6}
    intensity={1.5}
    color={[1, 1.25, 0.7]}
    />

<spotLight
    shadow-bias={0.0001}
    castShadow
    position={[5,5,0]}
    penumbra={0.5}
    angle={0.6}
    intensity={2}
    color={[0.14, 1.5, 1]}
    />

<Ground/>

<FloatingGrid/>

<EffectComposer>

  <DepthOfField focusDistance={0.0035} focalLength={0.01} bokehScale={3} height={480} />

  <Bloom
  blendFunction={BlendFunction.ADD}
  intensity={1.3}
  width={300}
  height={300}
  kernelSize={5}
  luminanceThreshold={0.15}
  luminanceSmoothing={0.025} />

  <ChromaticAberration
  blendFunction={BlendFunction.NORMAL}
  offset={[0.00005, 0.00012]} />

</EffectComposer>


    </>
  );
}
 

function App() {
  return (
    <Suspense fallback={null}>
      <Canvas shadows>
        <CarShow/>
      </Canvas>
    </Suspense>
  )
}

export default App;